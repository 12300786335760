import React from 'react';
const logo = require('./logo.png');

export function MixchButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
    return (
        <a {...props} className="button is-mixch is-white">
            <span className="icon is-small">
                <img src={logo} />
            </span>
        </a>
    );
}