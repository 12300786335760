import { createDocumentResource, createQueryResource, list } from 'retil';
import { Entry } from '@2022-chs-web/components/src/types';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
    for: list(entry),
    load: async () => {
        return [
            {
                id: 1,
                entry_id: 1,
                name: '荒井 竜成',
                "faculty": "体育学科",
                "grade": "",
                "birthday": "11月25日",
                "hometown": "東京都",
                "blood": "A型",
                "photo": [1,2,3,4,5,6,7,8],
                twitter: "chs2022_no1",
                instagram: "chs2022_no1",
                mixch: "17160407",
                interview1: "将来モデルや俳優の仕事をしたいからです。",
                interview2: "身長とまつ毛の長さです。",
                interview3: "モデル",
                interview4: "スケボー、bmx、ピストバイクで街乗り",
                interview5: "中高でサッカー部に所属していました。",
                interview6: "水問題",
                interview7: "自分を出来るだけ出していきます！負けず嫌いなのでグランプリ取って良い誕生日月にしたいと思います。フォローや投票等で応援よろしくお願いします！"
            },
            {
                id: 2,
                entry_id: 2,
                name: '河井 美月',
                "faculty": "中国語中国文化学科",
                "grade": "",
                "birthday": "11月12日",
                "hometown": "神奈川県",
                "blood": "",
                "photo": [1,2,3,4,5,6,7,8],
                twitter: "chs2022_no2",
                instagram: "chs2022_no2",
                mixch: "16154133",
                interview1: "新しいことに挑戦し活動の幅を広げたいと思ったのと、コロナ禍で大学生活の思い出が少ないと感じていたため大学四年生の最後に素敵な経験が出来たら良いなと思いました。",
                interview2: "笑顔です！",
                interview3: "社会人として働きながら芸能活動もしていきたいです",
                interview4: "ポーカー、ゴルフ、アニメや映画をみること、カラオケ、犬と戯れること",
                interview5: "バトントワリング",
                interview6: "SDGs🌱",
                interview7: "外見だけでなく内面も評価されるようになった今年のCHSコンテストで、自分らしさを出していけるよう日々の活動に一生懸命取り組み、皆さんと盛り上げていきたいです！"
            },
            {
                id: 4,
                entry_id: 4,
                name: '山川 紗英',
                "faculty": "教育学科",
                "grade": "",
                "birthday": "7月17日",
                "hometown": "東京都",
                "blood": "AB型",
                "photo": [1,2,3,4,6,7,8],
                twitter: "chs2022_no4",
                instagram: "chs2022_no4__",
                mixch: "17160511",
                interview1: "今までの人生の中で「やりたいな」と思うことは何度もあってもそれを行動に移すことが出来ず、「失敗しちゃうかも、自分にはダメかも」と何かしらの理由をつけて、挑戦することを諦めてきました。そんな自分を変えたくて、自分の可能性を知りたくて、挑戦してみたくて！ちょうどその時に友達に声を掛けてもらったので、この2022年chsコンテストに応募しました！",
                interview2: "私は、紫色💜が好きでいつもどこかしらに紫のものを身につけているのでそこに注目して欲しいです笑あとは、よく笑うので笑顔に注目してほしいです！",
                interview3: "将来の夢はまだ模索中なのですが、子どもが好きなので、子どもと関わる仕事がしたいなと思ってます。このchsコンテストを通じて、もっと将来の選択肢を広げていくことができたらいいなと思ってます！",
                interview4: "韓国ドラマを見ることです🇰🇷大学１年生の頃から韓ドラにハマりました♡ドラマだけじゃなくて、韓国の女の子アイドルも好きなので暇さえあればyoutubeでチッケムなどを見ています！最近好きなのは(G)I-DLEなどで、ガールクラッシュな曲が好きです",
                interview5: "中学の時はバドミントン部で、高校の時は部活には入っていませんでした。ですが、高校の時にテニスをスクールで習っていたので、テニスが得意です！",
                interview6: "私が興味のある社会問題は、教育格差です。教育格差とは、「生まれ育った環境により受けることのできる教育に格差が生まれること」です。私は教育学科で、授業で教育について学んでいます。今現在日本でも、学びたくても様々な理由により、学ぶことができてない子どもたちがたくさんいます。そんな現状を知り、教育格差という社会問題についてもっと学びたいと思っています。",
                interview7: "最後まで読んでくださりありがとうございます！たくさんの人に自分のことを知ってもらう機会というのは、滅多にない機会だと思うので、この機会に感謝するのと、同時にこのchsコンテストを裏で支えてくれている方たちに感謝の気持ちを常に忘れずに活動をしたいです。悔いのない終わり方ができるように頑張ります！これから約4ヶ月間よろしくお願いします💞✨"
            },
            {
                id: 5,
                entry_id: 5,
                name: '床櫻 昴秋',
                "faculty": "国文学科",
                "grade": "",
                "birthday": "10月28日",
                "hometown": "兵庫県",
                "blood": "A型",
                "photo": [1,2,3,4,5,6,7,8],
                twitter: "chs2022_no5",
                instagram: "chs2022_no5",
                mixch: "17161822",
                interview1: "大学生らしいことがしたかったから！コロナであんまり大学生活の思い出がないので、思い切って出場してみました。頑張ります！",
                interview2: "関西弁と、良い人感です！笑　人と話すのが好きなんで、聞き上手だねとかはよく言われます。関西人なんですけど、面白いこととかするのは苦手なんで勘弁してください！",
                interview3: "なりたい職業という意味でなら本に関わる編集や広告のキャッチコピーを考えたりする仕事についてみたいなと思っています。人生の目標的には、毎日楽しく過ごして生きていたいです！",
                interview4: "読書と運動と動画を見ることです！最近はネトフリとかよく見てます。小説も実用書から純文学、大衆文学など何でも読みます。あと漫画とかも。好きな本の話をさせたら止まらないです！",
                interview5: "中学は陸上でハードル、高校はラグビーをしていました！",
                interview6: "安倍総理の銃撃事件です。衝撃を受けました。日本で起きたとは思えず、歴史的な出来事に立ち会ってる気分になりました。",
                interview7: "簡単には負けへんで〜！精一杯頑張るんで、アホやなぁとか笑いながら適当に応援したって下さい！よろしくお願いします！"
            },
            {
                id: 6,
                entry_id: 6,
                name: '山口 麻美子',
                "faculty": "社会学科",
                "grade": "",
                "birthday": "11月16日",
                "hometown": "東京都",
                "blood": "A型",
                "photo": [1,2,3,4,5,6,7,8],
                twitter: "chs2022_no6",
                instagram: "chs2022__no6__",
                mixch: "17160398",
                interview1: "今までの顔の審査だけでなく色々な活動を通して審査される内容になったのでこんな私でも挑戦してみたいと思い、出場を決めました！",
                interview2: "21年間肥やしてきた丸顔",
                interview3: "あまりないのでこれから見つけていきたいです！",
                interview4: "ダーツと美味しいものを食べることと洋楽が好きなので音楽を聴くことです♪",
                interview5: "中高一貫校で5年間器械体操部に入っていました✨",
                interview6: "フードロスです！飲食店でバイトをしてるのでとても身近に感じてる社会問題だと思います。",
                interview7: "いつも勇気が出ない私が新しいことに挑戦しているので応援していただけると嬉しいです！グランプリ目指して精一杯がんばります💪"
            },
            {
                id: 7,
                entry_id: 7,
                name: '長岡 虎太郎',
                "faculty": "心理学科",
                "grade": "",
                "birthday": "6月26日",
                "hometown": "神奈川県",
                "blood": "A型",
                "photo": [1,2,3,4,5,6,7,8],
                twitter: "chs2022_no7",
                instagram: "chs2022_no7",
                mixch: "17153547",
                interview1: "自分を変えたかったのと、自分の新しい才能をみつけたくて！",
                interview2: "ぱっちりな目と涙袋です！",
                interview3: "まだ決まってないです！このコンテストを通して見つけれたらなと思います！最近はモデルのお仕事に興味があります！",
                interview4: "野球観戦と、美味しいもの巡りです！！",
                interview5: "見えないと思いますが、小学生からずっと剣道してました！",
                interview6: "環境問題に興味があります！",
                interview7: "今までのコンテストの枠組を超えた活動をします！応援してくれた人を絶対後悔させません！グランプリ必ずとります！"
            },
            {
                id: 8,
                entry_id: 8,
                name: '永井 暖乃',
                "faculty": "中国語中国文化学科",
                "grade": "",
                "birthday": "8月2日",
                "hometown": "埼玉県",
                "blood": "A型",
                "photo": [1,2,3,6,7,8],
                twitter: "chs2022_no8",
                instagram: "chs2022_no8",
                mixch: "15662193",
                interview1: "私はアナウンサーになることが目標なので、少しでも夢に近づくきっかけになればいいなと思い応募させていただきました。また、一度しかない大学生活で夢中になれるものを見つけ自分自身成長したいと思ったことも大きな理由のひとつです！",
                interview2: "3歳からモダンバレエを始め、中学生では社交ダンス、ジャズダンス、現在はフラダンスにも挑戦中で踊ることが大好きです。チャームポイントは笑った時に出る八重歯なのでぜひ覚えてください！！",
                interview3: "アナウンサー",
                interview4: "モダンバレエ、人と話すこと、舞台やドラマ鑑賞",
                interview5: "中学生の頃は社交ダンス、高校生では創作ダンスをしていたので部活中心の生活を送っていました！",
                interview6: "ジェンダーバイアス問題です。残念ながら日本では、海外に比べてジェンダー格差が大きいため無意識のうちにジェンダーによる偏見が多いことが現実です。私自身も生活する中でこのような場面に遭遇することがあるため、このような偏見を少なくしていきたいと思い興味を持ち始めました。",
                interview7: "今回の出場者では唯一の1年生ですが、少しでも多くの方に私のことを知っていただき笑顔を届けられるように頑張ります♪出場するからにはグランプリを目指したいので皆さん応援よろしくお願いします！"
            },
            {
                id: 9,
                entry_id: 9,
                name: '佐藤 敬太',
                "faculty": "地球科学科",
                "grade": "",
                "birthday": "12月21日",
                "hometown": "岩手県",
                "blood": "A型",
                "photo": [1,2,3,4,7,8],
                twitter: "chs2022_no9",
                instagram: "chs2022_no9",
                mixch: "17160802",
                interview1: "きっかけと言うと、有難い事に実行委員の方々に直接お話をいただいたから。なのですが、元々自分は音楽やエンタメ等の方面で将来は有名になりたいと思っているので、勧誘していただいたのとそのタイミングが重なりとても良い機会だと思ったので決断しました。",
                interview2: "自分からこんなことを言ったらきもいと思われるかもしれませんが、めちゃめちゃ多才です。基本的になんでもできます。今調子に乗りました。まじですがごめんなさい。",
                interview3: "海賊王！！！",
                interview4: "作曲編曲/ベース/歌/お絵描き/アイスホッケー/サッカー観戦(フロンターレサポ)/ライブハウス巡り・バンド探究/ダンス動画の鑑賞/夜中の散歩と運転/漫画の一気読み/東海オンエア/服屋・ラーメン屋巡り/お酒",
                interview5: "中学バスケ部、中学体幹部、高校軽音部、高校生物部",
                interview6: "LGBTQIA+、精神疾患や自殺の現状と実体、労働とハラスメントの相関関係について、少子高齢化と若年世代の選挙投票率について",
                interview7: "わしはかっこよくもないですしマメではないのでぶっちゃけ自信はありません。が。出るからには自分の魅力をしっかりと伝えられるように精一杯頑張ります。"
            }
        ];
    },
});
