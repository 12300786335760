import React, {useState} from 'react';
import classNames from "classnames";
import { useResource } from 'retil';
import { Link } from 'react-navi';
import {Entries, Photo} from '@2022-chs-web/components/src/components';
import {Entry} from "@2022-chs-web/components/src/types";
import { StepBox } from '@2022-chs-web/components/src/components/StepBox';
import { Hero, Footer } from '../../components';
import {entryList} from "../../resources";
import {EntryCard} from "../../components/card";
import { Twitter } from '../../components/twitter';
import TweetEmbed from 'react-tweet-embed';

export function TopApp() {
  const [entries] = useResource(entryList);
  const [openContent1, setOpenContent1] = useState(false);
  const [openContent2, setOpenContent2] = useState(false);
  const [openContent3, setOpenContent3] = useState(false);
  const [openContent4, setOpenContent4] = useState(false);
  return (
    <main className="TopApp">
      <Hero />
      <section id="about" className="section as-about-section">
        <div className="container" style={{ maxWidth: 550 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
          </div>
          <p
            className="notification is-padding-top-1-5 is-padding-bottom-1-5 is-padding-left-1-5 is-padding-right-1-5 is-margin-bottom-3 is-size-7-mobile"
            style={{ margin: '0 auto' }}
          >
            CHSコンテストとは2021年度より新設された、<strong className="has-text-marker">ジェンダーやルッキズムに囚われない新しい時代のコンテスト</strong>です。
            <br />
            <br />
            また昨年とは違い、社会問題や今までの人生で学んだこと、ファッションセンスなど一人一人の個性に着目出来るよう審査項目を4個に増やしました。
            <br />
            <br />
            <strong className="has-text-marker">出場者それぞれの将来の夢を叶える</strong>ために、ファイナリストに寄り添ったコンテスト作りをしていきます。
          </p>
        </div>
      </section>
      {/* FINALIST */}
      <section id="finalist" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Finalist</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={entries} noshuffle>
              {(list: Entry[]) =>
                  list.map((item: Entry) => (
                      <div
                          className={classNames('column is-4-tablet is-6-mobile')}
                          key={item.entry_id}
                      >
                        <EntryCard onlive={false} entry={item} href={`/profiles/${item.entry_id}`} />
                      </div>
                  ))
              }
            </Entries>
          </div>
        </div>
      </section>
      {/* OUTLINE */}
      <section id="outline" className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Outline</h2>
          </div>
          <div className="columns is-mobile is-multiline">
            <div className="column is-5">
              <p className="title is-6">大学名</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">日本大学文理学部</p>
            </div>
            <div className="column is-5">
              <p className="title is-6">コンテスト名</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">CHSコンテスト2022</p>
            </div>
            <div className="column is-5">
              <p className="title is-6">主催団体名</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">桜麗祭実行委員会</p>
            </div>
            <div className="column is-5">
              <p className="title is-6">開催形態</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">
              対面
              </p>
            </div>
            <div className="column is-5">
              <p className="title is-6">本番日</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">2022年11月5日・6日</p>
            </div>
            <div className="column is-5">
              <p className="title is-6">Twitter</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://twitter.com/CHScontest">@CHScontest</a>
                </span>
              </p>
            </div>
            <div className="column is-5">
              <p className="title is-6">Instagram</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://www.instagram.com/chscontest">@chscontest</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-1-5">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Twitter</h2>
          </div>
          <Twitter user="CHScontest" />
        </div>
      </section>
      <Footer />
      {/* <section className="as-fixed-footer">
        <div className="container">
          <Link href="/#finalist" className="is-flex is-flex-direction-column is-align-items-center has-text-centered has-text-white has-text-weight-bold">
              <p>『WEB投票』期間中！</p>
              <p className="is-size-7">投票は各出場者のページから！</p>
          </Link>
        </div>
      </section> */}
    </main>
  );
}
