import React, { useEffect } from 'react';
import { Entry, Onlive } from '@2022-chs-web/components/src/types';
import classNames from 'classnames';
import {
  Photo,
  InstagramButton,
  TwitterButton,
  ShowroomButton,
  MixchButton,
  VotedModal,
  Entries,
} from '@2022-chs-web/components/src/components';
import { Helmet } from 'react-navi-helmet-async';
import { Link, useCurrentRoute } from 'react-navi';
import { useResource } from 'retil';
import { entryList } from '../../resources';
import { Footer, HeroHeader } from '../../components';
import { EntryCard } from '../../components/card';
import { Twitter } from '../../components/twitter';

interface ProfileAppProps {
  entry: Entry;
}

export function ProfileApp({ entry }: ProfileAppProps) {
  const {
    url: { query },
  } = useCurrentRoute();
  const [entries] = useResource(entryList);
  useEffect(() => {
    const query: any = (window as any).$('a[data-fancybox]');
    query &&
      query.fancybox({
        loop: true,
        infobar: false,
        buttons: ['close'],
      });
  }, []);
  return (
    <main className="ProfileApp">
      {/*  style={{paddingBottom: 80}} */}
      <Helmet>
        <title>
          {entry.name ?? ''} | {process.env.REACT_APP_WEBSITE_NAME}
        </title>
        <meta content={`${entry.name ?? ''} | ${process.env.REACT_APP_WEBSITE_NAME}`} property="og:title" />
        <meta content={`${process.env.REACT_APP_URL + `/img/entry/${entry.entry_id}/ogp.jpg`}`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/profiles/${entry.entry_id}`} property="og:url" />
      </Helmet>
      <VotedModal {...query} src={`/img/entry/${entry.entry_id}/1_thumbnail.jpg`} />
      <HeroHeader />
      <section className="section as-profile">
        <div className="container">
          <nav className="entry-breadcrumb is-margin-bottom-1">
            <div className="field is-grouped is-grouped-multiline is-align-items-center">
              <p className="control is-size-6">
                <Link className="has-text-dark" href="/">
                  Top
                </Link>
              </p>
              <p className="control is-size-6">/</p>
              <p className="control is-size-6">
                No.{entry.entry_id} {entry.name}
              </p>
            </div>
          </nav>
          <div className="columns is-variable is-8">
            <div className="column">
              <Photo src={`/img/entry/${entry.entry_id}/1.jpg`} alt={entry.name ?? ''} />
            </div>
            <div className="column">
              <div className="is-margin-bottom-1">
                {entry.entry_id === 2 && (
                    <p className="is-warning is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag is-margin-bottom-1-5">グランプリ</p>
                )}
                {/* {entry.entry_id === 4 && (
                    <p className="is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag is-margin-bottom-1-5">準グランプリ</p>
                )}
                {entry.entry_id === 9 && (
                    <p className="is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag is-margin-bottom-1-5">準グランプリ</p>
                )} */}
                <p className="is-size-5 has-text-futura-pt has-text-italic">ENTRY NO.{entry.entry_id}</p>
              </div>
              <div className="columns is-size-3 is-variable is-multiline">
                <div className="column is-narrow">
                  <div className="columns">
                    <div className="column is-narrow" style={{ maxWidth: '100%' }}>
                      <h1>{entry.name}</h1>
                    </div>
                  </div>
                </div>
                <div className="column is-narrow is-padding-top-0-mobile">
                  <div className="columns is-mobile is-multiline is-variable is-2 is-wrap">
                    {entry.mixch && (
                      <div className="column is-narrow is-flex is-align-items-center">
                        <MixchButton
                          href={`https://mixch.tv/u/${entry.mixch}`}
                          target="_blank"
                        />
                      </div>
                    )}
                    {entry.instagram && (
                      <div className="column is-narrow">
                        <InstagramButton
                          href={`https://instagram.com/${entry.instagram}`}
                          target="_blank"
                          style={{ marginTop: 3 }}
                        />
                      </div>
                    )}
                    {entry.twitter && (
                      <div className="column is-narrow">
                        <TwitterButton
                          href={`https://twitter.com/${entry.twitter}`}
                          target="_blank"
                          style={{ marginTop: 3 }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Detail */}
              <div className="is-margin-bottom-1">
                <p className="is-margin-bottom-0-5 has-text-black has-text-weight-bold is-size-7-mobile">所属・学年</p>
                <p className="is-size-5 is-size-6-mobile">
                  {entry.faculty}
                  {entry.course && (
                    <>
                      <br />
                      {entry.course}
                    </>
                  )}{' '}
                  {entry.grade && `${entry.grade}`}
                </p>
              </div>
              {entry.hometown && (
                <div className="is-margin-bottom-1">
                  <p className="is-margin-bottom-0-5 has-text-black has-text-weight-bold is-size-7-mobile">出身地</p>
                  <p className="is-size-5 is-size-6-mobile">{entry.hometown}</p>
                </div>
              )}
              {entry.hometown && (
                <div className="is-margin-bottom-1">
                  <p className="is-margin-bottom-0-5 has-text-black has-text-weight-bold is-size-7-mobile">誕生日</p>
                  <p className="is-size-5 is-size-6-mobile">{entry.birthday}</p>
                </div>
              )}
              {entry.blood && (
                <div className="is-margin-bottom-2">
                  <p className="is-margin-bottom-0-5 has-text-black has-text-weight-bold is-size-7-mobile">血液型</p>
                  <p className="is-size-5 is-size-6-mobile">{entry.blood}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container" style={{ maxWidth: 1000 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Photo</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            {console.log(entry.photo)}
            {entry.photo?.map((p) =>
              <div className="column is-4-mobile is-3-tablet">
                <a href={`/img/entry/${entry.entry_id}/${p}.jpg`} data-fancybox="gallery">
                  <Photo src={`/img/entry/${entry.entry_id}/${p}_thumbnail.jpg`} alt={entry.name ?? ''} />
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="section has-background-white-bis">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Interview</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <div className="column">
              <div className="masonry">
                <div className="masonry-grid">
                  <article className="faq">
                    <p className="title">コンテストに出場したきっかけを教えて下さい。</p>
                    <p className="subtitle">{entry.interview1}</p>
                  </article>
                </div>
                <div className="masonry-grid">
                  <article className="faq">
                    <p className="title">ズバリ！アピールポイントは？</p>
                    <p className="subtitle">{entry.interview2}</p>
                  </article>
                </div>
                <div className="masonry-grid">
                  <article className="faq">
                    <p className="title">将来の夢はなんですか？</p>
                    <p className="subtitle">{entry.interview3}</p>
                  </article>
                </div>
                <div className="masonry-grid">
                  <article className="faq">
                    <p className="title">趣味はなんですか？</p>
                    <p className="subtitle">{entry.interview4}</p>
                  </article>
                </div>
                <div className="masonry-grid">
                  <article className="faq">
                    <p className="title">部活は何をしていましたか？</p>
                    <p className="subtitle">{entry.interview5}</p>
                  </article>
                </div>
                <div className="masonry-grid">
                  <article className="faq">
                    <p className="title">今自分が興味のある社会問題は？</p>
                    <p className="subtitle">{entry.interview6}</p>
                  </article>
                </div>
                <div className="masonry-grid">
                  <article className="faq">
                    <p className="title">意気込みをどうぞ！</p>
                    <p className="subtitle">{entry.interview7}</p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {entry.twitter && (
        <section className="section">
          <div className="container" style={{ maxWidth: 480 }}>
            <div className="is-margin-bottom-1-5">
              <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Twitter</h2>
            </div>
            <Twitter user={entry.twitter} />
          </div>
        </section>
      )}
      <section className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Finalist</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={entries} noshuffle>
              {(list: Entry[]) =>
                list.map((item: Entry) => (
                  <div className={classNames('column is-4-tablet is-6-mobile')} key={item.entry_id}>
                    <EntryCard onlive={false} entry={item} href={`/profiles/${item.entry_id}`} />
                  </div>
                ))
              }
            </Entries>
          </div>
        </div>
      </section>
      <Footer />
      
      {/* <section className="as-fixed-footer has-arrow">
       <div className="container">
         <Link href={`https://liff.line.me/1656170082-mjN5w2BK/vote/chs2022/N/${entry.entry_id}?liffId=1656170082-mjN5w2BK`}
                className="is-flex is-flex-direction-column is-align-items-center has-text-centered has-text-white has-text-weight-bold">
            <p className="is-size-7 is-margin-bottom-5px">『WEB投票』期間中！</p>
           <p>{entry.name} に投票する</p>
         </Link>
       </div>
      </section> */}
    </main>
  );
}
